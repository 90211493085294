import React, { FC, useEffect, useState } from 'react';

import { EmptyNumberStyled, NumberStyled, Wrapper } from 'components/util/styled';

interface IProps {
  cols: number;
  rows: number;
  max: number;
  
  getValues: (v:any) => void;
}

export const Bingo: FC<IProps> = ({
  cols,
  rows,
  max,
  getValues
}) => { 
  const [ values, setValues ] = useState([])
  const [ selected, setSelected ] = useState([])

  const select = (n, i)  => {
    selected[n][i] = !selected[n][i]
    setSelected(selected.slice())
    getValues(selected)
  }

  const isSelected = (n, i) => {
    return selected[n][i]
  }

  useEffect(() => {
    const values = randomValues({ cols, rows, max })
    const selected = values.map(row => ({}))
    setSelected(selected)
    setValues(values)
  }, [])
  return (
    <Wrapper>
      {values.map((row = [], n) => (
        <ul key={n}>{row.map((value, i) => (value
          ? <NumberStyled key={value}
              selected={isSelected(n, value)}
              onClick={() => select(n, value)}>{value}</NumberStyled>
          : <EmptyNumberStyled key={value} >&nbsp;</EmptyNumberStyled>
        ))}</ul>
      ))}
    </Wrapper>
  );
};

function randomValues({ cols, rows, max }) {
  const blockLength = max/rows
  const values = new Array(rows).fill(0).map((row, n) => {
    const blockIndex = blockLength * n
    const block = new Array(blockLength).fill(0).map((x, i) => blockIndex+i+1)
    
    const shuffled =  shuffle(block).slice(0, cols).sort((a, b) => a - b)
    shuffled.splice(n+1, 0, 0)
    return shuffled
  })
  return values
}

function shuffle(array) {
  let counter = array.length
  let temp
  let index

  // While there are elements in the array
  while (counter > 0) {
    // Pick a random index
    index = Math.floor(Math.random() * counter);

    // Decrease counter by 1
    counter--;

    // And swap the last element with it
    temp = array[counter];
    array[counter] = array[index];
    array[index] = temp;
  }

  return array;
}