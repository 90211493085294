/*eslint-disable*/
import React from "react";

import { PresentationPageWrapper } from "./styled";
import SectionBingo from "./Sections/SectionBingo";
import SectionFooter from "./Sections/SectionFooter";


export default function BingoPage() {
  return (
    <PresentationPageWrapper>
      <SectionBingo />
      <SectionFooter hideButton />
    </PresentationPageWrapper>
  );
}
