import * as yup from "yup";
import useForm from "../useForm";

export const fields = {
  name: {
    required: true,
    error: 'El nombre',
    label: 'Nombre',
    placeholder: '¿Cuál es tu nombre?',
  }
};


const schemas = () => ({
  name: yup.string().required()
});

const useBingoForm = () => {
  return useForm({ fields, schemas: schemas()});
};

export default useBingoForm;