import { dangerColor, grayColor } from 'components/styles/constants';
import { commonCss } from 'components/styles/util';
import styled, { css } from 'styled-components';



export const NumberStyled = styled.li`
  background: ${grayColor[2]};

  ${({ selected })=> selected && css`
    background: ${dangerColor[0]};
  `}
`
export const MessageHolderStyled = styled.p`
  height: 5rem;
  margin-top: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  & span {
    display: block;
    color: ${dangerColor[0]};
    font-size: 2rem;
    font-weight: bold;
  }
  & em {
    margin: 1rem;
    display: block;
    color: ${grayColor[0]};
    position: absolute;
    font-size: 1rem;
    font-weight: bold;
    width: 100%;
    top: 2rem;
  }
`
export const EmptyNumberStyled = styled(NumberStyled)`
  background: ${grayColor[1]};
`
export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  
  margin-top: 0.5rem;

  & ul {
    padding: 0;
    border: 1px solid;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    list-style: none;
  }
  & li {
    font-size: 2rem;
    height: 4rem;
    width: 4rem;

    ${commonCss.breakpointDown('xs')`
      height: 3rem;
      width: 3rem;
      font-size: 1.2rem;
    `}
    border: 1px solid;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`