import React, { useState } from "react";

import useBingoForm from "../use/form/impl/useBingoForm";
import Box from "components/layout/Box";


import Form from "components/form/form/Form"
import { SubmitButtonStyled, TextFieldStyled } from "components/form/form/styled";
import { Alert } from "components/message/Alert";
import { Bingo } from "components/util/Bingo";
import { MessageHolderStyled } from "components/util/styled";

const COLS = 3
const ROWS = 3
const MAX = 30

const contactURL = '/taller.php'
const defaultValues = {
  name: '',
}

async function fetchUrlJSON(url, params) {
  const response = await fetch(url, {
    method: "POST",
    body: JSON.stringify(params),
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    },
  });
  return await response.json();
}

const defaultAlert = {
  open: false,
  success: false,
  message: ''
}
const alertSuccess = {
  open: true,
  success: true,
  message: 'Tu resultado se ha enviado correctamente'
}
const alertError = {
  open: true,
  success: false,
  message: 'Se ha producido un error. Vuelve a intentarlo más tarde'
}

const BingoFormCompose = () => {
  const { form, disabled, fieldProps } = useBingoForm();
  const [ alert, setAlert ] = useState(defaultAlert)
  const [ finished, setFinished ] = useState(false)
  const [ selected, setSelected ] = useState([])
  const onSubmit = async e => {
    e.preventDefault()

    const { status } = await fetchUrlJSON(contactURL, {
      ...form.getValues(),
      ...{
        message: selected
      }
    })

    setAlert(status === 'success' ? alertSuccess : alertError)
  }
  const handleClose = () => {
    setAlert(defaultAlert)
  }

  const handleValues = values => {
    const bingo = []
    const countLens = []

    setFinished(false)

    Object.keys(values).forEach((rowIndex, i) => {
      const row = values[rowIndex]
      const items = Object.keys(row).filter(item => row[item])
      
      countLens.push(items.length)

      if (items.length === COLS && selected[i].length < COLS) {
        setFinished(true)
      }

      bingo.push(items)
    })
    setSelected(bingo)
  }

  
  const isLine = finished
  const isBingo = finished && selected.filter(o => o.length === COLS).length === ROWS

  return (
    <>
      <Form form={form} onSubmit={onSubmit} defaultValues={defaultValues}>
        <TextFieldStyled {...fieldProps.name} />
        <Bingo cols={COLS} rows={ROWS} max={MAX} getValues={handleValues} />
        <MessageHolderStyled>
          { isLine && !isBingo && <span>¡¡¡LÍNEA!!!</span>}
          { isBingo && <span>BINGO!!!</span>}
          { isLine && disabled && <em>Rellena tu nombre para poder enviar los datos</em>}
        </MessageHolderStyled>
        <SubmitButtonStyled onClick={onSubmit} disabled={disabled || (!isLine && !isBingo)}>
          Enviar
        </SubmitButtonStyled>
      </Form>
      <Alert
        success={alert.success}
        openAlert={alert.open}
        text={<span>{alert.message}</span>}
        handleClose={handleClose}
      />
    </>
  )
}

export default BingoFormCompose;

