import React from "react";

import BingoFormCompose from "compose/BingoFormCompose";

import images from "components/styles/images";
import Box from "components/layout/Box";
import { CardContactStyled, CardBodyBingoStyled } from "views/Cards/styled";
import { HeroStyled } from "views/PresentationPage/Sections/styled";

export default function SectionBingo() {

  return (
    <>
      <HeroStyled height='20' image={images.bg.bingo} color="info" intensity='' shade={0.7}>
      </HeroStyled>
      <Box variant='small'>
        <CardContactStyled>
          <CardBodyBingoStyled>
            <BingoFormCompose />
          </CardBodyBingoStyled>
        </CardContactStyled>
      </Box>
    </>
  );
}

